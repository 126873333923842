.hero {

	background-color: get-color(light, 1);

	.hero-content-container {
		position: absolute;
            top: 64px;
            left: 0;
            right: 0;
            text-align: center;
	}

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}

	.important-numbers {
		background-color: get-color(light,1);
	}

	.banner{
		margin: auto;
		padding-bottom: 48px;
		
		.image-mobile {
		display: none;
		}
	 }
	 

	.carousel .control-dots {
		text-align: right;
		padding-right: 48px;
		.dot {
			background: get-color(light, 1);
			opacity: inherit;

			&.selected {
				background: get-color(primary, 1);
				border: 1px solid get-color(light, 1);
			}
		}
	}

	.split-wrap .split-item {
		flex-direction: column;
		align-items: flex-start;
	}

	.hero-content {
		hr {
			width: 40%;
			background-color: get-color(primary, 1);
		  }
	}

	.carousel-wrapper {
			.slide {
				div:after {
					position:absolute;
					width:100%; height:100%;
					top:0; left:0;
					background:rgba(0,0,0,0.6);
					opacity:1;
					transition: all 0.5s;
					-webkit-transition: all 0.5s;
				}

				img {
					filter: brightness(0.6);
				}
			}
	}
	
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=x-large' ) {

	.hero {

		.hero-content {
			h1 {
				font-size: 52px;
				line-height: 52px;
				padding: 0;
			}

			h3 {
				font-size: 18px;
				line-height: 28px;
			}
		}

		.button-donate {
			margin-top: 40px;
			box-shadow: #1c1b1b 3px 3px 7px;
		}

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media('<large') {
	.hero {
		.important-numbers {
			margin-top: 90px;
		}
	}
}

@include media( '<=medium' ) {
	.hero {
		.hero-content {
			h1 {
				font-size: 24px;
				line-height: 24px;
			}
	
			h3 {
				font-size: 16px;
				line-height: 20px;
			}

			.hero-subtitle {
				display: none;
			}

		}
		.button-donate {
			width: 300px;
			height: 80px;
			margin-top: 70px;

			div {
				font-size: 20px;
			}
		}

		.banner {
			.image-web{
				display: none;
			  }
		   
			  .image-mobile{
				display: block;
			  }
		}
	}
}

	

@include media( '>x-large' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}



