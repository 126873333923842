.testimonial {

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;

		hr {
			max-width: 250px;
            margin-left: 0;
		}
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile / 2);
			margin-left: -($testimonial-items--padding__mobile / 2);
			margin-top: -($testimonial-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile / 2;
					padding-right: $testimonial-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $testimonial-item--width;
		max-width: $testimonial-item--width;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile / 2;
		}
	}

	.tiles-item-inner {
		padding: $testimonial-item--inner-padding-v;
		// padding-bottom: $testimonial-item--inner-padding-v;
		// padding-left: $testimonial-item--inner-padding-h;
		// padding-right: $testimonial-item--inner-padding-h;
	}

	.tiles-item-parent:nth-child(odd) {
		background: #f9f9f9;
	}
}

.testimonial-header,
.header-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 20px;
	 
	.section-header {
		padding-bottom: 0;

	}
	.tabs {
		padding-bottom: 32px;

	}
}

.testimonial-item-header {
	border-width: 1px;
	border-style: solid;
	border-color: get-color(primary, 1);
	border-radius: 5px;
    margin-right: auto;
    padding: 5px;
}

.testimonial-item-content {
	flex-grow: 1;
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ( $testimonial-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop / 2);
				margin-left: -($testimonial-items--padding__desktop / 2);
				margin-top: -($testimonial-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop / 2;
						padding-right: $testimonial-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop / 2;
			}
		}
	}
}
