.cta {

	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;

	hr {
		max-width: 250px;
		margin-left: 0;
	}
}

.cta-action {
	display: flex;

	a {
		text-decoration: none;
	}
}

.cta-divider {
	background-color: get-color(light, 1);
	height: 20px;
	width: 100%;
}

.cta-container{
	background: get-color(light, 3);
	width: 100%; 
	
	&:first-of-type{
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	&:last-of-type{
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
}


@include media( '>x-large' ) {

	.cta {    
		flex-wrap: nowrap ;
	
		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}

	.cta-divider {
		width: 50px;
		height: auto;
	}
	
	.cta-container:first-of-type {
		.cta-inner {
			margin-left: 25%;
		}
	}
	
	.cta-container:last-of-type {
		.cta-inner {
			margin-right: 25%;
		}
	}
}
