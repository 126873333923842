// Carousel (core/elements/_carousel.scss)

// Background color
$carousel-bullet--bg: (
	carousel-button: 					get-color(primary, 1),
	carousel-button-hover: 				get-color(light, 3),
	carousel-button-active:				get-color(light, 3),
	// ↓ Inverted colors
	carousel-button-inverse: 			get-color(dark, 2),
	carousel-button-hover-inverse: 		get-color(dark, 3),
	carousel-button-active-inverse: 	get-color(primary, 1),
);

// More carousel settings
$carousel-bullet--outer-width:		20px;
$carousel-bullet--outer-height:		20px;
$carousel-bullet--width:			8px;
$carousel-bullet--height:			8px;
$carousel-bullet--radius: 			50%;

// Don't change line below!
$icon--color: map-push($icon--color, $carousel-bullet--bg);
