.section {
    white-space: pre-line;
}

.section-inner {
    position: relative; // To always display inner elements above pseudo decorative stuff
    padding-top: $section--padding__mobile;
    padding-bottom: $section--padding__mobile;
}

.section-header {
    padding-bottom: $section-header--padding__mobile;
    
    hr {
        max-width: 250px;
    }
}

