// News (core/sections/_news.scss)
$news--padding-t__mobile: 		null;		// news, top padding (mobile)
$news--padding-b__mobile: 		null;		// news, bottom padding (mobile)
$news--padding-t__desktop: 		null;		// news, top padding (desktop)
$news--padding-b__desktop: 		null;		// news, bottom padding (desktop)
$news-header--padding__mobile: 	null;		// news header, bottom padding (mobile)
$news-header--padding__desktop:	null;		// news header, bottom padding (desktop)
$news-items--padding__mobile: 	null;		// padding between news items (mobile)
$news-items--padding__desktop: 	null;		// padding between news items (desktop)
$news-item--width: 				1080px;		// news item, width
$news-item--inner-padding-v: 	24px;		// news item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$news-item--inner-padding-h: 	null;		// news item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
