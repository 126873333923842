.testimonial {

	// background: aliceblue;
	// background: get-color(dark, 3);

    &.has-bg-color-cut {
		position: relative;
		background-color: transparent;
        z-index: auto;
        
		&::before {
			content: '';
			width: 100%;
			height: calc(100% - 140px);
			position: absolute;
			left: 0;
			top: 0;
			background: get-color(dark, 1);
            clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
			z-index: -3;
		}
    }
}


.testimonial-item-image {

	img {
		border-radius: 50%;
	}
}

.testimonial-item-amount {
	font-weight: 700;
	padding: 0px 15px;
	font-size: x-large;
}

.testimonial-item-name {
	color: get-color(dark, 1);
}

.testimonial-item-footer {
	padding-top: 20px;
	font-size: large;
	line-height: 24px;
}

.testimonial-item-link {
	color: get-color(dark, 1);

	a {
        color: get-color(dark, 1);
        text-decoration: none;

        &:hover {
            color: get-color(dark, 2);
        }
	}
}