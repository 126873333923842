.button {
  display: inline-flex;
  @include font-size(button);
  @include font-weight(button);
  padding: (
      (
          $button-form--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) / 2
    )
    ($button--padding-h - $button-form--border-width);
  height: $button-form--height;
  text-decoration: none !important;
  text-transform: $button--transform;
  color: color(button-light);
  background-color: color-bg(button-light);
  border-width: $button-form--border-width;
  border-style: $button-form--border-style;
  border-color: color-border(button-light);
  border-radius: $button--radius;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  letter-spacing: inherit;
  white-space: nowrap;
  transition: background 0.15s ease;

  &:active {
    outline: 0;
  }

  &:hover {
    background-color: color-bg(button-light-hover);
    border-color: color-border(button-light-hover);
  }

  &.is-loading {
    position: relative;
    color: transparent !important;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: get-line-height(button);
      height: get-line-height(button);
      margin-left: -(get-line-height(button) / 2);
      margin-top: -(get-line-height(button) / 2);
      top: 50%;
      left: 50%;
      border: $button-loading--thickness solid color(button-light);
      border-radius: 50%;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
      animation: button-loading 0.6s infinite linear;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    color: color(button-disabled);
    background-color: color-bg(button-disabled) !important;
    border-color: color-border(button-disabled);

    &.is-loading {
      &::after {
        border-color: color(button-disabled);
      }
    }
  }
}

.button-sm {
  padding: (
      (
          $button-form-sm--height - get-line-height(button) -
            ($button-form--border-width * 2)
        ) / 2
    )
    ($button-sm--padding-h - $button-form--border-width);
  height: $button-form-sm--height;
}

.button-dark {
  color: color(button-dark);
  background-color: color-bg(button-dark);
  border-color: color-border(button-dark);

  &:hover {
    background-color: color-bg(button-dark-hover);
    border-color: color-border(button-dark-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-dark);
    }
  }
}

.button-primary {
  color: color(button-primary);
  background-color: color-bg(button-primary);
  border-color: color-border(button-primary);
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px 0px;

  &:hover {
    background-color: color-bg(button-primary-hover);
    border-color: color-border(button-primary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-primary);
    }
  }
}

.button-donate {
  position: relative;
  margin: auto;
  background-color: get-color(primary, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 330px;
  height: 90px;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  line-height: 10px;
  font-weight: 600;
  text-decoration: none !important;
  border-width: 1px;
  border-style: solid;
  border-color: get-color(primary, 1);
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  transition: background 0.15s ease;
  box-shadow: #1c1b1b 6px 6px 7px;

  .image-wrapper {
    border: 1px solid white;
    border-radius: 50%;
    width: 64px;
    height: 64px;

    img {
      margin: auto;
      margin-top: 22px;
    }
  }

  div {
    color: get-color(light, 1);
    font-size: 32px;
    line-height: 42px;
  }
}

.button-secondary {
  color: color(button-secondary);
  background-color: color-bg(button-secondary);
  border-color: color-border(button-secondary);

  &:hover {
    background-color: color-bg(button-secondary-hover);
    border-color: color-border(button-secondary-hover);
  }

  &.is-loading {
    &::after {
      border-color: color(button-secondary);
    }
  }
}

.button-block {
  display: flex;
  width: 100%;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: -($button-group-padding / 2);
  margin-left: -($button-group-padding / 2);
  margin-top: -($button-group-padding / 2);

  &:last-of-type {
    margin-bottom: -($button-group-padding / 2);
  }

  &:not(:last-of-type) {
    margin-bottom: ($button-group-padding / 2);
  }

  > [class*="button"] {
    margin: $button-group-padding / 2;
  }
}

@include media("<=large") {
  .button-wide-mobile {
    width: 100%;
    max-width: $button--max-size-mobile;
  }
}

@keyframes button-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button-back-to-top {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: get-color(primary, 1);
  color: get-color(light, 1);
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  font-size: 12px;
  white-space: normal;
  line-height: 12px;
  font-weight: 400;

  &:hover {
    background-color: lighten(get-color(primary, 1), 5%)
  }
}
