.features-tiles-item-donation {
    color: get-color(dark, 1);  
    text-align: center;
    margin: auto;
}

.features-tiles-item-content {
    text-align: left;

    h6 {
        @include font-family(heading);
    }
}

.features-tiles-item-image {
    display: inline-flex;
	border-radius: 50%;
    background: get-color(primary, 1);  
}

.important-numbers-item-content {
    border: 1px solid get-color(light, 3);
    border-right: none;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 15px;
    height: 100%;

    > * {
        flex: 1 1 0px;
        padding: 0 4px;
    }

    h5 {
        margin-top: auto;
        margin-bottom: auto;
        @include font-family(heading);
        border-right: 2px solid get-color(light, 3)
    }
}


.last-tile-item {
    .important-numbers-item-content {
        border-right: 1px solid get-color(light, 3);
    }
}
    

@include media ("<=large") {
    .important-numbers-item-content {
        border-right: 1px solid get-color(light, 3);
    }
}

